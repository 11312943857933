import { 
    Page,
    Button,
    Logo,
    Splash
} from "./styles";
import { useNavigate } from "react-router";

// import clouds from '../../assets/images/clouds.png';
// import accordion from '../../assets/images/accordion.png';
// import umbrella from '../../assets/images/umbrella.png';
// import houses from '../../assets/images/houses.png';

const Home = () => {
    const navigate = useNavigate();
    return (
    <Page onClick={() => navigate("/events/cheiro-de-chuva/tickets")}>
        <div style={{
            position: "relative",
            width: "500px", height: "500px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Splash />
            <Logo />
        </div>
        <Button 
            to='/events/tangerica/tickets'>
                <span 
                    style={{
                        fontFamily: "Gods Must", 
                        lineHeight: "1.65rem", 
                        marginRight: "7px",
                        fontWeight: 400,
                        fontSize: "1.4rem",
                    }}>comprar ingressos</span> 
        </Button>
            
    </Page>
    )
}

export default Home;