import { Link } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'

import logo from '../../assets/images/logo.png';
import bg from '../../assets/images/bg.png';
import splash from '../../assets/images/splash.png';

const Page = styled.div`
    height: 100vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-image: url(${bg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    color: white;
    font-weight: 700;
`

const Logo = styled.div`
    position: absolute;
    width: calc(1528px/8);
    height: calc(1176px/8);

    background-image: url(${logo});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    z-index: 1;
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Splash = styled.div`
    position: absolute;
    width: calc(512px/1.5);
    height: calc(500px/1.5);

    display: flex;
    align-items: center;
    justify-content: center;

    background-image: url(${splash});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    z-index: 1;

    animation: ${rotate} 30s linear infinite;
`

const Button = styled(Link)`
    font-family: Lato;
    font-weight: 400;
    border-radius: 100px;
    font-size: 1.4rem;
    margin-top: 10%;
    max-width: 300px;
    line-height: 2rem;

    text-align: center;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 100px;
    text-decoration: none;
    
    background-color: #f6e9c7;
    color: #b63615;
    border: 2px solid #b63615;

    padding: 10px 50px;

    z-index: 2;
`

export {
    Page,
    Button,
    Logo,
    Splash
}