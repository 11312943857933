import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { BsDashLg, BsInfoLg, BsPlusLg } from "react-icons/bs";

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper'
import 'swiper/css';

import {
    Container,
    ListContainer, 
    Wrapper, 
    WrapperLink,
    Description, 
    Price, 
    SellerImage,
    ImageWrapper,
    Informative,
    TicketInfo,
    QuantitySelector,
    Button,
    Shake,
} from './styles';

import client from "../../../assets/service/client";
import { useQuery } from "react-query";
import Loader from "../../../assets/components/loader/Loader";
import { AnimatePresence } from "framer-motion";

import umbrella from "../../../assets/images/umbrella.png"
import cheers from "../../../assets/images/cheers.png"
import tambourine from "../../../assets/images/tambourine.png"

const TicketList = () => {
    let [showTickets, setShowTickets] = useState(true);
    let [showOrders, setShowOrders] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    let alias = "cheiro-de-chuva";

    let promo = searchParams.get("promo");
    let error = searchParams.get("error");

    let [quantity, setQuantity] = useState({});
    let [products, setProducts] = useState([]);

    const getQuantity = (ticketSpecId) => {
        return quantity[ticketSpecId] || 0;
    }

    const incrementQuantity = (ticketSpecId, multiplier, orderLimit) => {
        let delta = multiplier || 1
        let limit = orderLimit || Infinity

        let updatedQuantity = {}
        updatedQuantity[ticketSpecId] = quantity[ticketSpecId] ? quantity[ticketSpecId] + delta : delta
        updatedQuantity[ticketSpecId] = updatedQuantity[ticketSpecId] > limit ? limit : updatedQuantity[ticketSpecId]

        setQuantity(quantity => ({
            ...quantity,
            ...updatedQuantity
        }))
    }

    const decrementQuantity = (ticketSpecId, multiplier) => {
        let delta = multiplier || 1
        let updatedQuantity = {}
        updatedQuantity[ticketSpecId] = quantity[ticketSpecId] && quantity[ticketSpecId] - delta

        setQuantity(quantity => ({
            ...quantity,
            ...updatedQuantity
        }))
    }

    const parseProducts = (tickets, promo) => {
        let state = []
        for (let ticketSpecId of Object.keys(quantity)) {
            if (quantity[ticketSpecId]) {
                let ticketSpec = tickets.filter(t => t.id == ticketSpecId)[0]
                state.push({
                    ...ticketSpec, quantity: quantity[ticketSpecId], codeUsed: promo
                })
            }
        } 

        setProducts([...state]);
    }

    useEffect(() => {
        if (products.length) {
            document.getElementById("link_to_checkout").click()
        }
    }, [products])

    useEffect(() => {
        document.documentElement.style.setProperty('--bg-color', '#f6e9c7');
    }, [])

    const getEventByAlias = async () => {
        return await client.get(`event/alias/${alias}`)
        .then(res => {
            // if (res.data.event.pixelId) {
            //     window.fbq('init', `${res.data.event.pixelId}`);
            //     window.fbq('trackSingle', `${res.data.event.pixelId}`, 'ViewContent')
            //     window.fbq('trackSingle', process.env.REACT_APP_PIXEL_ID, 'ViewContent');
            // }
            return(res.data.event);
        })
        .catch(err => err)
    }

    const getActiveTickets = async (eventId) => {
        return await client.get(`event/${eventId}`)
        .then(res => {
            return(res.data.event.activeTicketSpecs);
        })
        .catch(err => err)
    }

    const getPromoTickets = async (eventId) => {
        if (promo) {
            return await client.get(`ticket/event/${eventId}/promo/${promo}`)
            .then(res => {
                return(res.data.ticketSpecs);
            })
            .catch(err => err)
        } else return null
    }

    const getEventOrders = async (eventId) => {
        return await client.get(`event/${eventId}/orders`)
        .then(res => {
            return(res.data.orders);
        })
        .catch(err => err)
    }

    const { isLoading: isEventLoading, isError: isEventError, data: event, error: eventError } = useQuery(['event', alias], getEventByAlias, { keepPreviousData: false });
    const { isLoading: isTicketsLoading, isError: isTicketsError, data: tickets, error: ticketsError } = useQuery(['eventTickets', alias], () => getActiveTickets(event.id), {enabled: (!!showTickets) && (!!event), keepPreviousData: false});

    const { isLoading: isPromoLoading, isError: isPromoError, data: promoTickets, error: promoError } = useQuery(['eventTicketsPromo', alias], () => getPromoTickets(event.id), {enabled: (!!showTickets) && (!!event), keepPreviousData: false});

    const { isLoading: isOrdersLoading, isError: isOrdersError, data: orders, error: ordersError } = useQuery(['eventOrders', alias], () => getEventOrders(event.id), {enabled: (!!showOrders) && (!!event), keepPreviousData: false});

    if (isEventLoading || (showTickets && isTicketsLoading) || (showOrders && isOrdersLoading)) {
        return (
            <Container>
                <Loader />
            </Container>
        )
    }

    SwiperCore.use([Autoplay]);
    return (
        <Container className="no-scroll" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }}>
            {/* <div style={{minHeight:"100px"}} /> */}
            <div style={{
                width: "100px", height: "100px",
                minWidth: "100px", minHeight: "100px",
                // backgroundColor: "#26ccf9",
                backgroundImage: `url(${umbrella})`,
                backgroundSize: "60px",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                zIndex: '-1'
            }} />
            <div style={{
                fontFamily: "Gods Must", 
                lineHeight: "1rem", 
                fontWeight: 400,
                fontSize: "0.8rem",
                textAlign: 'center',
                width: "90%",
                display: "flex",
                flexDirection: "column",
                gap: "25px",
                color: '#b63615'
            }}>
                <div>
                    Cheiro de Chuva é aquele cheiro indescritível de felicidade e aconchego. É aquele cheiro de terra molhada que perfuma o vento, lava a tristeza e traz a alegria. É o som das gotas caindo na calçada em uma tarde ensolarada, trazendo a sensação de paz.
                </div>

                <div>
                    Cheiro de Chuva é a felicidade de saber que o arco-íris está por vir, e que a felicidade está em aprender a se molhar com alegria!
                </div>
            
                <div>
                    Cheiro de Chuva é leveza e alegria, igual o espírito brasileiro. Cheiro de Chuva é uma mistura perfeita da música brasileira: uma gota de forró e outra de carnaval.
                </div>

                <div>
                    Vem com a gente regar essa felicidade!
                </div>
            </div>

            <div style={{minHeight:"40px"}} />

            <Shake>
            <div style={{
                width: "80px", height: "80px",
                minWidth: "80px", minHeight: "80px",
                backgroundImage: `url(${cheers})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center"
            }} />
            </Shake>

            <div style={{minHeight:"40px"}} />

            <div style={{width: "90%"}}>
                <iframe title="spotify" src="https://open.spotify.com/embed/playlist/4Mq3jI3I3dtCwQdRddlKz0?utm_source=generator" width="100%" height="152" frameBorder="0"  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </div>

            <div style={{minHeight:"40px"}} />

            <div style={{
                fontFamily: "Gods Must", 
                lineHeight: "1rem", 
                fontWeight: 400,
                fontSize: "1rem",
                textAlign: 'center',
                width: "90%",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                color: '#b63615'
            }}>
                <div>
                    14/11 às 21h
                </div>
                <div>
                    Edifício Itália, São Paulo
                </div>
                </div>

            <div style={{minHeight:"40px"}} />

            <div style={{
                width: "80px", height: "80px",
                minWidth: "80px", minHeight: "80px",
                backgroundImage: `url(${tambourine})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center"
            }} />

            <div style={{minHeight:"40px"}} />

            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    // position: "fixed", bottom: "0px", zIndex: "20"
                }}
            >
                <AnimatePresence exitBeforeEnter={true} initial={false}>
                {
                    error && <div key="randomkey" style={{fontSize: "0.8em", width: "80%", textAlign: "center", marginBottom: "16px", color: "#181818"}}>Os ingressos selecionados não estão mais disponíveis. Por favor, selecione novamente seus ingressos.</div>
                }
                {
                    showTickets && (tickets || promoTickets) && (
                        <>
                        <ListContainer key="event-tickets" initial={{ x: -300, opacity: 0 }} animate={{ x: 0, opacity: 1 }} exit={{ x: -300, opacity: 0 }} transition={{ duration: 0.2 }}>
                        {
                        !!tickets && !!tickets?.length &&
                            tickets.map((ticket, index) => {
                                
                                return (
                                    <Wrapper key={ticket.id}>
                                        <Informative>
                                            <TicketInfo>
                                                <Description>{
                                                ticket.description.includes("Masc") ? "Ingresso Masc ~ Open Bar" : 
                                                ticket.description.includes("Fem") ? "Ingresso Fem ~ Open Bar" :
                                                ticket.description
                                                }</Description>
                                                <Price>R${ticket.price}</Price>
                                            </TicketInfo>
                                            <QuantitySelector>
                                                <BsDashLg onClick={() => decrementQuantity(ticket.id, ticket.multiplier)} style={{"fontSize": "0.8em", "color": "#f6e9c7"}}/> 
                                                <span style={{"color": "#f6e9c7"}}>{getQuantity(ticket.id)}</span>
                                                <BsPlusLg onClick={() => incrementQuantity(ticket.id, ticket.multiplier, ticket.orderLimit)} style={{"fontSize": "0.8em", "color": "#f6e9c7"}}/>
                                            </QuantitySelector>
                                        </Informative>
                                    </Wrapper>
                                )
                            })
                        }
                        {
                        !!promoTickets && !!promoTickets?.length &&
                            promoTickets.map((ticket, index) => {
                                if (!!tickets && !!tickets?.length && tickets.map(t => t.id).includes(ticket.id)) { return <></> }
                                return (
                                    <Wrapper key={ticket.id}>
                                        <Informative>
                                            <TicketInfo>
                                                <Description>{ticket.description}</Description>
                                                <Price>R${ticket.price}</Price>
                                            </TicketInfo>
                                            <QuantitySelector>
                                                <BsDashLg onClick={() => decrementQuantity(ticket.id, ticket.multiplier)} style={{"fontSize": "0.8em", "color": "#f6e9c7"}}/> 
                                                <span style={{color: "#f6e9c7"}}>{getQuantity(ticket.id)}</span>
                                                <BsPlusLg onClick={() => incrementQuantity(ticket.id, ticket.multiplier, ticket.orderLimit)} style={{"fontSize": "0.8em", "color": "#f6e9c7"}}/>
                                            </QuantitySelector>
                                        </Informative>
                                    </Wrapper>
                                )
                            })
                        }
                        </ListContainer>
                        
                        <Button onClick={() => parseProducts(promoTickets?.length ? [...tickets, ...promoTickets] : tickets, promo)}>
                            comprar
                        </Button>
                        <Link id="link_to_checkout" style={{"display": "none"}} to={`/events/${alias}/tickets/checkout`} state={{ products }}></Link>
                        </>
                    )
                }
                </AnimatePresence>
            </div>
        </Container>
    )
}

export default TicketList